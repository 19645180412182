import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { TagType, OperateType, ShareType, SourceType, } from '../../../../typings/organization/label-manage';
import UpdateGroupBase from './update-group-base';
let UpdateGroup = class UpdateGroup extends UpdateGroupBase {
    async comfirm() {
        super.comfirm();
    }
    cancel() {
        super.cancel();
    }
    async onValueChange(v) {
        if (v) {
            this.modalTitle = (this.operateType === OperateType.Add ? '添加' : '编辑') + '分类';
            const groupData = this.groupDetail;
            this.loading = true;
            if (this.operateType === OperateType.Edit) {
                const detail = await this.getDetail(groupData.id);
                this.form.name = detail.name;
                this.form.org = detail.orgId;
                this.isSelectedParent = true;
            }
            else {
                if (groupData) {
                    this.isSelectedParent = true;
                    this.form.org = groupData.id;
                }
            }
            const orgParams = {
                labelType: TagType.Org,
                shareType: ShareType.Org,
                labelId: '',
                sourceType: SourceType.SelfMaintain,
            };
            this.orgList = await this.getOrgAndGroup(orgParams);
            this.loading = false;
        }
        else {
            this.form = {
                name: '',
                org: undefined,
            };
            this.isSelectedParent = false;
        }
    }
};
__decorate([
    Watch('value')
], UpdateGroup.prototype, "onValueChange", null);
UpdateGroup = __decorate([
    Component({
        name: 'UpdateGroup',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], UpdateGroup);
export default UpdateGroup;
